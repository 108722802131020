import { store } from "@/store";
import { App } from "vue";
import { ModelsActionTypes } from "@/core/enums/Models/Actions";

class Models{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Models.vueInstance = app;
    }

    public static getModelsByManufacturerId(payload: any) {
        store
        .dispatch(ModelsActionTypes.GET_MODELS, payload)
    }
}

export default Models;
import { MutationTree } from 'vuex';

import { Models } from '@/core/types/Models';

import { State } from './state';
import { ModelsMutationTypes } from '@/core/enums/Models/Mutations';

export type Mutations<S = State> = {
  [ModelsMutationTypes.SET_MODELS](state: S, payload: Array<Models>): void;
  [ModelsMutationTypes.SET_MODEL_LOADED](state: S, payload: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ModelsMutationTypes.SET_MODELS](state: State, models: Array<Models>) {
    state.models = models;
  },
  [ModelsMutationTypes.SET_MODEL_LOADED](state: State, payload: any) {
    state.loaded = payload;
  },
};

import { MutationTree } from 'vuex';

import { TrimLevels } from '@/core/types/TrimLevels';

import { State } from './state';
import { TrimLevelsMutationTypes } from '@/core/enums/TrimLevels/Mutations';

export type Mutations<S = State> = {
  [TrimLevelsMutationTypes.SET_TRIM_LEVELS](state: S, payload: Array<TrimLevels>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [TrimLevelsMutationTypes.SET_TRIM_LEVELS](state: State, trimLevels: Array<TrimLevels>) {
    state.trimLevels = trimLevels;
  },
};

import { MutationTree } from 'vuex';

import { Vehicle, Bike } from '@/core/types';

import { State } from './state';
import { VehicleMutationTypes } from '@/core/enums/Vehicles/Mutations';

export type Mutations<S = State> = {
  [VehicleMutationTypes.SET_VEHICLE](state: S, payload: Vehicle): void;
  [VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [VehicleMutationTypes.SET_VEHICLE](state: State, vehicle: Vehicle) {
    state.vehicle = vehicle;
  },

  [VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE](state: State, payload: Boolean) {
    state.loading = payload;
  },

  [VehicleMutationTypes.SET_BIKE](state: State, bike: Bike) {
    state.bike = bike;
  },
};

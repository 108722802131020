<template>
<section class="vechile-detail-section">
    <BaiSkeletonDriver v-if="$route.name == 'DriverDetails'" />
    <!-- <BaiSkeletonResultListing v-if="$route.name == 'QoutesDetails'" /> -->
    <SkeletonVehicle v-if="$route.name == 'VehicleDetails'" />
</section>
</template>

<script>
import SkeletonVehicle from '@/layouts/skeleton/vehicle.vue'
import BaiSkeletonDriver from '@/layouts/skeleton/driver.vue'
import BaiSkeletonResultListing from '@/layouts/skeleton/resultListing.vue'
export default {
    components: {
        SkeletonVehicle,
        BaiSkeletonDriver,
        BaiSkeletonResultListing
    }
}
</script>
import { EstimatedValues } from '@/core/types/EstimatedValues';


export type State = {
  estimatedValues: EstimatedValues;
}

export const state: State = {
  estimatedValues: {
    old_max: 0,
    old_min: 0,
    new_min: 0,
    new_max: 0
  },
};

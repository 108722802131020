import { store } from "@/store";
import { App } from "vue";
import { BuyNowActionTypes } from "@/core/enums/BuyNow/Actions";
import { BuyNowMutationTypes } from "@/core/enums/BuyNow/Mutations";


class BuyNow{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        BuyNow.vueInstance = app;
    }

    public static async getQoutationReference(payload: object) {
        return await store
        .dispatch(BuyNowActionTypes.POST_BUY_NOW, payload)
    }

    public static setCheckout(payload: Object) {
        store.commit(BuyNowMutationTypes.SET_CHECKOUT, payload)
    }

    public static setBuyNow(payload: Object) {
        window.localStorage.setItem('buyNow', JSON.stringify(payload));
        store.commit(BuyNowMutationTypes.SET_SELECTED_POLICY, payload)
    }

}

export default BuyNow;
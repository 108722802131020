import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CountriesMutationTypes } from '@/core/enums/Countries/Mutations';
import { CountriesActionTypes } from '@/core/enums/Countries/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [CountriesActionTypes.GET_COUNTRIES](
    { commit }: AugmentedActionContext
  ): any;
  [CountriesActionTypes.GET_COUNTRY_CODE](
    { commit }: AugmentedActionContext
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CountriesActionTypes.GET_COUNTRIES]({ commit }) {
    const data = await axios.post(LinkTypes.GET_COUNTRIES);
    try {
      commit(CountriesMutationTypes.SET_COUNTRIES, data.data.data.countries);
    }
    catch(err) {
      console.log(err)
    }
  },

  async [CountriesActionTypes.GET_COUNTRY_CODE]({ commit }) {
    const data = await axios.post(LinkTypes.GET_COUNTRIES_CODE);
    try {
      commit(CountriesMutationTypes.SET_COUNTRY_CODE, data.data.data.codes);
    }
    catch(err) {
      console.log(err)
    }
  },
};

import { Stepper } from '@/core/types/Steppers';

export type State = {
  stepper: Object,
}

export const state: State = {
  stepper: {
    vehicle: '',
    driver: '',
    compare: '',
    cover: ''
  }
};

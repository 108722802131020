import { store } from "@/store";
import { App } from "vue";
import { ManufaturersActionTypes } from "@/core/enums/Manufacturers/Actions";

class Manufacturers{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Manufacturers.vueInstance = app;
    }

    public static getManufacturers(payload: any) {
        store
        .dispatch(ManufaturersActionTypes.GET_MANUFACTURERS,payload)
    }

    public static getEngineCapcity(payload: any) {
        store
        .dispatch(ManufaturersActionTypes.GET_ENGINE_CAPCITY,payload)
    }
}

export default Manufacturers;
import { MutationTree } from 'vuex';

import { Manufacturers } from '@/core/types/Manufacturers';

import { State } from './state';
import { ManufacturersMutationTypes } from '@/core/enums/Manufacturers/Mutations';

export type Mutations<S = State> = {
  [ManufacturersMutationTypes.SET_MANUFACTURERS](state: S, payload: Array<Manufacturers>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ManufacturersMutationTypes.SET_MANUFACTURERS](state: State, manufacturer: Array<Manufacturers>) {
    state.manufacturers = manufacturer;
  },
};

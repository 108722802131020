import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Vehicle } from '@/core/types';

import { State } from './state';

export type Getters = {
  getVehicle(state: State): Vehicle | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getVehicle: (state) => state.vehicle,
};

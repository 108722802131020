<template>
    <div class="container" style="margin-top: -75px">
        <div class="vechile-list-container main-container">
            <div class="vechile-list_row">
                <div class="we-found text-center">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <p>We have found 0 Car Insurance plans</p>
                        </div>
                    </div>
                </div>
                <div class="page-title-block result-listing-title inner">
                    <div class="row align-items-center gx-lg-5">
                        <div class="col-lg-3 left-col">
                            <div class="page-title m-0">
                                <h2 class="mb-0">Compare</h2>
                            </div>
                        </div>
                        <div class="col-lg-6 mid-col">
                            <div class="result-tabs-nav text-center">
                                <div class="nav nav-tabs d-inline-flex border-0" id="nav-tab" role="tablist">
                                    <button class="compare-link active" id="all-tab" data-bs-toggle="tab"
                                        data-bs-target="#all" type="button" role="tab" aria-controls="all"
                                        aria-selected="true">All</button>
                                    <button class="compare-link" id="comprehensive-tab" data-bs-toggle="tab"
                                        data-bs-target="#comprehensive" type="button" role="tab"
                                        aria-controls="comprehensive"
                                        aria-selected="false">Comprehensive</button>
                                    <button class="compare-link" id="third-party-tab" data-bs-toggle="tab"
                                        data-bs-target="#third-party" type="button" role="tab"
                                        aria-controls="third-party" aria-selected="false">Third Party</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 right-col">
                            <div class="title-desc">
                                <p>The prices you see here are exclusive of 5% VAT
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
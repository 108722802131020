import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { TrimLevels } from '@/core/types/TrimLevels';

import { State } from './state';

export type Getters = {
  getTrimLevels(state: State): Array<TrimLevels> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getTrimLevels: (state) => state.trimLevels,
};

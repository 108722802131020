import {
    Store as VuexStore,
    CommitOptions,
    DispatchOptions,
    Module,
  } from 'vuex';
  
  // TODO: How to surpass cyclical dependency linting errors cleanly?
  // eslint-disable-next-line import/no-cycle
  import { RootState } from '@/store';
  
  import { state } from './state';
  // eslint-disable-next-line import/no-cycle
  // import { getters, Getters } from './getters';
  import { mutations, Mutations } from './mutations';
  
  import type { State } from './state';
  
  export { State };
  
  export type LangStore<S = State> = Omit<VuexStore<S>,  | 'commit'>
  & {
    commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
      key: K,
      payload: P,
      options?: CommitOptions
    ): ReturnType<Mutations[K]>;
  } 
  
  export const store: Module<State, RootState> = {
    state,
    mutations,
    // TODO: With namespaced option turned on, having problem how to use dispatch with action types...
    // But without it, a bigger store might have clashes in namings
    // namespaced: true,
  };
  
import { Models } from '@/core/types/Models';

const language = window.localStorage.getItem('language');


export type State = {
  models: Array<Models>;
  loaded: boolean
}

export const state: State = {
  models: [{value: -1, label: language === 'ar' ? 'طراز السيارة' : 'Car Model', manufacturer_id: 0, id: 0, car: language === 'ar' ? 'طراز السيارة' : 'Car Model'}],
  loaded: false
};

import { store } from "@/store";
import { App } from "vue";
import { EstimatedValuesActionTypes } from "@/core/enums/EstimatedValues/Actions";
import { EstimatedValuesMutationTypes } from "@/core/enums/EstimatedValues/Mutations";

class EstimatedValues{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        EstimatedValues.vueInstance = app;
    }

    public static getEstimatedValuesByYearManufacturerIdModelIdTrimLevelId(payload: object) {
        store
        .dispatch(EstimatedValuesActionTypes.GET_ESTIMATED_VALUES, payload)
    }

    public static resetEstimatedCarValues() {
        store.commit(EstimatedValuesMutationTypes.SET_ESTIMATED_VALUES, {old_min: 0, old_max: 0});
    }
}

export default EstimatedValues;
import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Countries, Country_code } from '@/core/types/Countries';

import { State } from './state';

export type Getters = {
  getCountries(state: State): Array<Countries> | null;
  getCountryCode(state: State): Array<Country_code> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCountries: (state) => state.countries,
  getCountryCode: (state) => state.countryCode,
};

import { TrimLevels } from '@/core/types/TrimLevels';

const language = window.localStorage.getItem('language');

export type State = {
  trimLevels: Array<TrimLevels>;
}

export const state: State = {
  trimLevels: [{id: 0, label: language === 'ar' ? 'تفاصيل الطراز' : 'Model Details', trim_level: language === 'ar' ? 'تفاصيل الطراز' : 'Model Details', value: -1}],
};

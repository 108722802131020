import { MutationTree } from 'vuex';

import { BuyNow, Policy, Checkout } from '@/core/types/BuyNow';

import { State } from './state';
import { BuyNowMutationTypes } from '@/core/enums/BuyNow/Mutations';

export type Mutations<S = State> = {
  [BuyNowMutationTypes.SET_BUY_NOW](state: S, payload: BuyNow): void;
  [BuyNowMutationTypes.SET_CHECKOUT](state: S, payload: Object): void;
  [BuyNowMutationTypes.SET_SELECTED_POLICY](state: S, payload: Policy): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [BuyNowMutationTypes.SET_BUY_NOW](state: State, buyNow: BuyNow) {
    state.buyNow = buyNow;
  },
  [BuyNowMutationTypes.SET_CHECKOUT](state: State, payload: Checkout) {
    state.checkout = payload;
  },
  [BuyNowMutationTypes.SET_SELECTED_POLICY](state: State, payload: Policy) {
    state.policy = payload;
  },
};

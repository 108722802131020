import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Comprehensive, ThirdParty } from '@/core/types/Qoutes';

import { State } from './state';

export type Getters = {
  getComprehensive(state: State): Array<Comprehensive> | null;
  getThirdParty(state: State): Array<ThirdParty> | null;
  getComapreData(state: State): Array<Object> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getComprehensive: (state) => state.comprehensive,
  getThirdParty: (state) => state.thirdParty,
  getComapreData: (state) => state.comprehensive,
};

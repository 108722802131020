import { MutationTree } from 'vuex';

import { State } from './state';
import { StepperMutationTypes } from '@/core/enums/Stepper/Mutations';

export type Mutations<S = State> = {
  [StepperMutationTypes.SET_STEP_VEHICLE_DETAILS](state: S, payload: Object): any;
}

export const mutations: MutationTree<State> & Mutations = {
  [StepperMutationTypes.SET_STEP_VEHICLE_DETAILS](state: State, payload: Object) {

    state.stepper = payload
  },

};
